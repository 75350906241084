import { api } from '~services/index';

export const occasionsApi = api.injectEndpoints({
  endpoints: (builder: any) => ({
    occasion: builder.query({
      query: ({ limit, imageOccasions = false }: any) => ({
        url: `/api/occasion/${limit}?imageOccasions=${imageOccasions}`,
      }),
      providesTags: ['Occasion'],
    }),
    occasionDetails: builder.query({
      query: ({ _id }: any) => ({
        url: `/api/occasion/details/${_id}`,
      }),
      providesTags: ['OccasionDetails'],
    }),
  }),
});

export const { useOccasionQuery, useOccasionDetailsQuery } = occasionsApi;
